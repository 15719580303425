import Layout from "../components/Layout"
import { Link } from "gatsby"
import React from "react"
import Seo from "../components/seo"

const GlossaryPage = () => {

  return (
    <Layout >
      <Seo title="Glossary" />
      
      <div className="max-w-1920 mx-auto p-5 lg:p-12">
        <h1 className="page-heading md:text-center">Glossary</h1>
        
        
        <dl className="max-w-prose mx-auto mt-10 lg:mt-20">

          <dt id="gravure" className="font-serif font-bold text-2xl md:text-3xl text-purple-400 pb-2">Gravure <a className="hash-link" href="#gravure">#</a></dt>
          <dd><p className="mb-4">A thin, printed finish; applied to guitars to look like more expensive wood. The "gravure" name comes from the rotogravure printing process. Early Talmans featured faux birdseye maple finishes on the neck and headstock. Some guitars also used the gravure finish on the top (and back) of the body to simulate the look of a high-end, figured wood texture like quilted maple. 
            The gravure finish is very convincing. People not "in the know" will try to list vintage Talman guitars for sale at very high prices because they believe it's made from an exotic wood.
            </p>
            <p className="mb-4">Ibanez chose to use this finish technique (along with other things) to keep the prices of their guitars competitive. You can learn more about that on the early Talman model pages: <Link to="/tc530">TC530</Link>, <Link to="/tv650">TV650</Link>, <Link to="/tv750">TV750</Link></p>
            <p>Other manufactures, besides Ibanez, used this same technique during the same era. The best known was Fender's Japanese made "Foto Flame" line of guitars. 
              This Fender tradename is why you will sometimes find Talmans listed for sale with the term "photo flame" or "foto flame".</p>
          </dd>

          <dt id="resoncast" className="font-serif font-bold text-2xl md:text-3xl text-purple-400 pt-10 pb-2">Resoncast <a className="hash-link" href="#resoncast">#</a></dt>
          <dd>An Ibanez marketing term for an engineered, composite wood material used to create early Talman guitar bodies (1994-95). It's a compressed mixture of resin and alder sawdust, better known as <abbr title="Medium Density Fiberboard">MDF</abbr>.</dd>
        
        </dl>
        
      </div>
    </Layout>
  )
}

export default GlossaryPage